import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import SecondaryButton from "../components/SecondaryButton"
import "../styles/error.css"

const Error = () => {
  return (
    <>
      <Layout title="Not Found">
        <main className="error-page">
          <section className="error-container">
            <article className="error-header">
              <div className="underline"></div>
              <h1>Oops... </h1>
              <h2>Page not found!</h2>
              <div className="btn-wrapper">
                <SecondaryButton
                  buttonText="Back to home"
                  url="/"
                ></SecondaryButton>
              </div>
            </article>
            <div className="error-img">
              <StaticImage
                src="../assets/unbox.svg"
                alt="unboxing illustration"
                className="error-img-svg"
                placeholder="blurred"
              />
            </div>
          </section>
        </main>
      </Layout>
    </>
  )
}

export default Error
